import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useRef, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import "./hamburgers.css"
import { CartItems } from "../components/cart"
import { FiShoppingCart } from "react-icons/fi"
import { useShoppingCart } from 'use-shopping-cart'


const CartButton = styled.div`
  top: 3px;
  //right: 15px;
  position: relative;
  @media (min-width: 1024px) {
    position: fixed;
    top: 29px;
    right: 30px;
  }
  z-index: 6000;

  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
    div{
      display:grid;
      align-items:center;
      justify-items:center;
      background:lightgray;
      border-radius:100px;
      width:22px;
      height:22px;
      display:block;
      position:absolute;
      top:-10px;
      left:15px;
      span{
        line-height:1.4;
      }
    }
  }
`

const Head = styled.section`
  ${tw`w-full fixed`}
  //background:#fff;
  left: 50%;
  margin-left: -50%;
  padding: 10px 50px;
  z-index: 30;
  top: 0;
  .logo {
    ${tw` text-xl sm:text-2xl inline-flex uppercase`}
    @media(max-width:768px) {
      //width: 125px !important;
      transform: scale(0.75);
    }
  }
  a {
    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 600px) {
    &.home {
      position: relative;
    }
  }
  //position: fixed;
  //@media (min-width: 600px) {
  &.white {
    background: #fff;
    background: #fff;
    position: fixed;
  }
  //}
  @media (max-width: 1024px) {
    padding: 0;
    //position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 0px 1fr;
    align-items: center;
    justify-items: center;
    background: #fff;
  }
`

const TitleSpan = styled.span`
  color: ${({ color }) => color};
`

const Nav = styled.nav`
  ${tw`hidden lg:inline-flex justify-end fixed`}
  //background:#fff;
  padding-right: 50px;
  width: 75%;
  @media (min-width: 1440px) {
    width: 94%;
  }
  ul {
    ${tw`flex justify-between pl-0`}
    width:550px;
    padding-top: 8px;
    li {
      ${tw`list-none lowercase font-light text-lg`}
      font-family:roboto,courier;
      a {
        color: #363636;
        &.active{
          font-weight:700;
        }
      }
    }
  }
`

const NavMobile = styled.nav`
  ${tw`block lg:hidden absolute right-0 top-0 bg-white w-full h-screen z-30`}
  ul {
    padding: 0;
    li {
      list-style-type: none;
      text-align: center;
      margin-bottom: 25px;
    }
  }
  .logo {
    margin: 9px auto;
    display: block !important;
  }
`

const Menu = styled.button`
  ${tw` lg:hidden right-0 top-0 z-40`}
  margin-top:10px;
  &:focus {
    outline: none;
  }
`

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, deps)
}

const Header = ({ siteTitle, location }) => {
  const [hideOnScroll, setHideOnScroll] = useState(false)
  const shadowStyle = hideOnScroll ? "white" : ""
  const { cartCount } = useShoppingCart()

  //console.log(location)

  let distance
  let pos

  if (location.pathname === "/") {
    distance = -150
    pos = "home"
  } else {
    distance = 25
    pos = "notHome"
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < distance
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  const [isToggled, setToggled] = useState(false)

  const toggleMenu = () => {
    setToggled(!isToggled)
  }

  const [close, setClose] = useState(false)

  const toggleCart = () => {
    setClose(!close)
  }
  const showCart = () => {
    setClose(true)
  }

  const data = useStaticQuery(graphql`
    query {
      sanityHomePage {
        logoImageColor {
          alt
          asset {
            fixed(width: 200, height: 46) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  `)

  let activeAbout
  let activeContact


  if (location.hash === "#about"){
    activeAbout = "active";
  }
  if (location.hash === "#contact"){
    activeContact = "active";
  }

  

  return (
    <Head className={`header ${shadowStyle} ${pos}`}>
      <Menu
        className={
          isToggled
            ? `hamburger hamburger--squeeze is-active`
            : `hamburger hamburger--squeeze`
        }
        onClick={toggleMenu}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </Menu>
      <Nav>
        <ul>
          <li>
            <Link activeClassName="active" to="/curations/">Curations</Link>
          </li>
          <li>
            <Link activeClassName="active" to="/goods">Goods</Link>
          </li>
          <li>
            <Link activeClassName="active" to="/furniture">Furniture</Link>
          </li>
          <li>
            <Link activeClassName="active" to="/concepts">Concepts</Link>
          </li>
          <li>
            <Link className={activeAbout} href="/#about">About</Link>
          </li>
          <li>
            <Link activeClassName="active" to="/blog">Blog</Link>
          </li>
          <li>
            <Link className={activeContact} to="/#contact">Contact</Link>
          </li>
        </ul>
      </Nav>
      {isToggled && (
        <NavMobile>
          <Link to="/">
            <Img
              className="logo"
              fixed={
                data.sanityHomePage.logoImageColor &&
                data.sanityHomePage.logoImageColor.asset.fixed
              }
            />
          </Link>
          <ul>
            <li>
              <Link to="/curations">Curations</Link>
            </li>
            <li>
              <Link to="/goods">Goods</Link>
            </li>
            <li>
              <Link to="/furniture">Furniture</Link>
            </li>
            <li>
              <Link to="/concepts">Concepts</Link>
            </li>
            <li>
              <Link to="/#about">About</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="/#contact">Contact</a>
            </li>
          </ul>
        </NavMobile>
      )}
      <Link to="/">
        <Img
          className="logo"
          fixed={
            data.sanityHomePage.logoImageColor &&
            data.sanityHomePage.logoImageColor.asset.fixed
          }
          imgStyle={{ objectFit: "contain" }}
        />
      </Link>
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart color="#545557" size={25} />
          

          {cartCount > 0 && <div><span>{cartCount}</span></div>}
          
        </button>
      </CartButton> */}
    </Head>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
